<template>
  <div class="app-box">
    <div class="title-box">
      <div class="line"></div>
      <p class="title">广告位列表</p>
    </div>
    <a-divider style="margin-left: -10px" />
    <a-form-model class="form-search" :colon="false" layout="inline" :model="query" labelAlign="left"
      @submit="handleSearch" @submit.native.prevent>
      <a-row>
        <a-form-model-item style="float: right; margin-right: 0">
          <a-button type="primary" html-type="submit">
            查询
          </a-button>
          <a-button @click="Reset">重置</a-button>
        </a-form-model-item>
        <a-form-model-item label="名称">
          <a-input v-model="query.title" placeholder="名称"></a-input>
        </a-form-model-item>
      </a-row>
    </a-form-model>
    <a-row class="table-action-list" style="margin-top: 20px">
      <a-button type="primary" @click="add" style=" margin-left: 10px">+新增</a-button>
    </a-row>
    <a-table class="main-table" :pagination="{
      current: query.page,
      total: pagetotal,
      showTotal: (pagetotal, range) => `共 ${pagetotal} 条数据`,
    }" @change="listChange" :columns="columns" :data-source="data" rowKey="ad_id" style="margin-top: 14px" v-viewer>
      <span slot="img" slot-scope="text,record">
        <img :src="record.img" class="img-qr">
      </span>
      <span slot="is_on" slot-scope="text, record">
        <a-tag :color="record.is_on === 1 ? '#74CE78' : '#757776'">
          {{ record.is_on === 1 ? '开启' : '关闭' }}
        </a-tag>
        <a-button type="link" @click="on(record.ad_id)">
          {{ record.is_on === 1 ? '关闭' : '开启' }}
        </a-button>
      </span>
      <span slot="action" slot-scope="text, record">
        <a-space>
          <a @click="toEdit(record.ad_id)">编辑</a>
          <a-popconfirm title="确认删除该广告" ok-text="是" cancel-text="否" @confirm="del(record.ad_id)">
            <a style="color: #E0352B">删除</a>
          </a-popconfirm>
        </a-space>
      </span>
    </a-table>
  </div>
</template>

<script>
import {
  domain
} from '@/siteInfo.js'

export default {
  data() {
    return {
      query: {
        page: 1,
        limit: 10
      },
      pagetotal: 0,
      ad_id: null,
      visible: false,
      columns: [{
        title: '序号',
        customRender: (text, record, index) => index + 1
      },
      {
        title: '名称',
        dataIndex: 'title'
      },
      {
        title: '广告图',
        scopedSlots: {
          customRender: 'img'
        }
      },
      {
        title: '状态',
        scopedSlots: {
          customRender: 'is_on'
        }
      },
      {
        title: '创建日期',
        dataIndex: 'create_at_str'
      },
      {
        title: '操作',
        scopedSlots: {
          customRender: 'action'
        }
      }
      ],
      data: [],
    }
  },
  created() {
    this.getList()
  },
  methods: {
    Reset() {
      this.query = {
        page: 1,
        limit: this.query.limit,
      }
      this.getList()
    },
    toEdit(id) {
      this.$router.push({
        path: '/advertEdit',
        query: {
          id: id,
        }
      })
    },
    add() {
      this.$router.push({
        path: '/advertEdit',
      })
    },
    del(id) {
      this.$post(domain + '/admin/v1/system/adDelete', { ad_id: id }).then(res => {
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.getList()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    on(id) {
      this.$post(domain + '/admin/v1/system/adOn', { ad_id: id }).then(res => {
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.getList()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    getList() {
      this.$post(domain + '/admin/v1/system/adList', this.query).then(res => {
        if (res.code === 0) {
          this.data = res.data.list
          this.data = res.data.total
          console.log('datadatadatadata', this.data)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    listChange(e) {
      this.query.page = e.current
      this.getList();
    },
    handleSearch() {
      this.query.page = 1
      this.getList();
    },
  }
}
</script>

<style lang="less" scoped>
.title-box {
  display: flex;
  align-items: center;
  margin-left: 8px;

  .line {
    width: 5px;
    height: 14px;
    background-color: #4E80F8;
  }

  .title {
    color: #383F50;
    font-size: 16px;
    margin-left: 5px;
  }
}

.img-qr {
  height: 30px;
  cursor: pointer;
}
</style>

<style lang="less">
.form-search {
  .ant-form-item-label {
    width: 70px;
  }
}
</style>
